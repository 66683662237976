import React from "react"
import {Link} from "gatsby"
import CookieConsent from "react-cookie-consent";

import {rhythm, scale} from "../utils/typography"
import logo from '../../content/assets/improvebadcode.svg';

class Layout extends React.Component {
  render() {
    const {location, title, children} = this.props;
    const rootPath = `${__PATH_PREFIX__}/`;
    let header;

    if (location.pathname === rootPath) {
      header = (
        <Link
          style={{
            boxShadow: `none`,
            textDecoration: `none`,
            color: `inherit`,
            display: 'flex',
            alignItems: 'center',
          }}
          to={`/`}
        >
          <img src={logo} alt="logo" height={60} style={{marginRight: '1.5em'}}/>
          <h1
            style={{
              fontFamily: 'Molengo, sans-serif',
              fontWeight: 'normal',
              color: '#1e6b87',
              ...scale(1.2),
              marginBottom: rhythm(1.5),
              marginTop: 0,
            }}
          >
            {title}
          </h1>
        </Link>
      )
    } else {
      header = (

        <Link
          style={{
            boxShadow: `none`,
            textDecoration: `none`,
            color: `inherit`,
            display: 'flex',
            alignItems: 'center'
          }}
          to={`/`}
        >
          <img src={logo} alt="logo" height={40} style={{marginRight: '1.5em'}}/>
          <h2
            style={{
              fontFamily: `Molengo, sans-serif`,
              marginTop: 0,
              fontWeight: 'normal',
              fontSize: '34px',
              color: '#1e6b87'
            }}
          >{title}</h2>
        </Link>
      )
    }
    return (
      <div
        style={{
          marginLeft: `auto`,
          marginRight: `auto`,
          maxWidth: rhythm(24),
          padding: `${rhythm(1.5)} ${rhythm(3 / 4)}`,
        }}
      >
        <header>{header}</header>
        <main>{children}</main>
        <CookieConsent
          location="bottom"
          buttonText="Accept"
          declineButtonText="Decline"
          enableDeclineButton
          flipButtons={true}
          cookieName="gatsby-gdpr-google-analytics"
          cookieValue={true}
          declineCookieValue={false}
          style={{ backgroundColor: "#ffffff", color: '#000000', fontSize: '15px', fontFamily: 'Molengo', boxShadow: 'rgba(0, 0, 0, 0.5) 0px 9px 10px 5px', borderTop: '1px solid #cbd6e2' }}
          buttonStyle={{ color: "#ffffff", backgroundColor: '#000000', borderRadius: '3px', fontSize: "15px" }}
          declineButtonStyle={{ color: "#000000", backgroundColor: '#ffffff', borderRadius: '3px', border: '1px solid #000000', fontSize: "15px" }}
          expires={150}
        >
          This website stores cookies on your computer. These cookies are used to collect information about how you interact with this website and allow us to remember you.<br />We use this information in order to improve and customize your browsing experience and for analytics and metrics about our visitors on this website.
          <br /><br />
          If you decline, your information won’t be tracked when you visit this website. A single cookie will be used in your browser to remember your preference not to be tracked.
        </CookieConsent>
        <footer>
          ©{new Date().getFullYear()} <a href="https://www.bele-serv.com">Bele-serv</a>,
          Created with 💚 and built using <a href="https://www.gatsbyjs.org">Gatsby</a>
        </footer>
      </div>
    )
  }
}

export default Layout
