/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import {StaticQuery, graphql} from 'gatsby';
import logo from '../../content/assets/logo.png';

const query = graphql`
  query SEO {
    site {
      siteMetadata {
        defaultTitle: title
        titleTemplate
        defaultDescription: description
        siteUrl
        defaultImage: image
        twitterUsername
      }
    }
  }
`;

const SEO = ({title, description, lang, meta, image, pathname, article}) => (
  <StaticQuery
    query={query}
    render={({
               site: {
                 siteMetadata: {
                   defaultTitle,
                   titleTemplate,
                   defaultDescription,
                   siteUrl,
                   defaultImage,
                   twitterUsername,
                 },
               },
             }) => {
      const seo = {
        title: title || defaultTitle,
        description: description || defaultDescription,
        image: `${siteUrl}${image || defaultImage}`,
        url: `${siteUrl}${pathname || "/"}`,
      };

      return (
        <>
          <Helmet title={seo.title} titleTemplate={titleTemplate}>
            <meta name="description" content={seo.description}/>
            <meta name="image" content={seo.image}/>
            {seo.url && <meta property="og:url" content={seo.url}/>}
            {(article ? true : null) && (
              <meta property="og:type" content="article"/>
            )}
            {seo.title && <meta property="og:title" content={seo.title}/>}
            {seo.description && (
              <meta property="og:description" content={seo.description}/>
            )}
            {seo.image && <meta property="og:image" content={seo.image}/>}
            <meta property="og:logo" content={`${siteUrl}${logo}`}/>
            <meta name="twitter:card" content="summary_large_image"/>
            {twitterUsername && (
              <meta name="twitter:creator" content={twitterUsername}/>
            )}
            {seo.title && <meta name="twitter:title" content={seo.title}/>}
            {seo.description && (
              <meta name="twitter:description" content={seo.description}/>
            )}
            {seo.image && <meta name="twitter:image" content={seo.image}/>}
          </Helmet>
        </>
      )
    }}
  />
);
//   const { site } = useStaticQuery(
//     graphql`
//       query {
//         site {
//           siteMetadata {
//             title
//             description
//             author
//             title
//             titleTemplate
//             description
//             siteUrl: url
//             defaultImage: image
//             twitterUsername
//           }
//         }
//       }
//     `
//   );
//
//   const metaDescription = description || site.siteMetadata.description;
//
//   const seo = {
//     title: title || defaultTitle,
//     description: description || defaultDescription,
//     image: `${siteUrl}${image || defaultImage}`,
//     url: `${siteUrl}${pathname || "/"}`,
//   };
//
//   return (
//     <Helmet title={seo.title} titleTemplate={titleTemplate}>
//       <meta name="description" content={seo.description} />
//       <meta name="image" content={seo.image} />
//       {seo.url && <meta property="og:url" content={seo.url} />}
//       {(article ? true : null) && (
//         <meta property="og:type" content="article" />
//       )}
//       {seo.title && <meta property="og:title" content={seo.title} />}
//       {seo.description && (
//         <meta property="og:description" content={seo.description} />
//       )}
//       {seo.image && <meta property="og:image" content={seo.image} />}
//       <meta name="twitter:card" content="summary_large_image" />
//       {twitterUsername && (
//         <meta name="twitter:creator" content={twitterUsername} />
//       )}
//       {seo.title && <meta name="twitter:title" content={seo.title} />}
//       {seo.description && (
//         <meta name="twitter:description" content={seo.description} />
//       )}
//       {seo.image && <meta name="twitter:image" content={seo.image} />}
//     </Helmet>
//     // <Helmet
//     //   htmlAttributes={{
//     //     lang,
//     //   }}
//     //   title={title}
//     //   titleTemplate={`%s | ${site.siteMetadata.title}`}
//     //   meta={[
//     //     {
//     //       name: `description`,
//     //       content: metaDescription,
//     //     },
//     //     {
//     //       property: `og:title`,
//     //       content: title,
//     //     },
//     //     {
//     //       property: `og:description`,
//     //       content: metaDescription,
//     //     },
//     //     {
//     //       property: `og:type`,
//     //       content: `website`,
//     //     },
//     //     {
//     //       name: `twitter:card`,
//     //       content: `summary`,
//     //     },
//     //     {
//     //       name: `twitter:creator`,
//     //       content: site.siteMetadata.author,
//     //     },
//     //     {
//     //       name: `twitter:title`,
//     //       content: title,
//     //     },
//     //     {
//     //       name: `twitter:description`,
//     //       content: metaDescription,
//     //     },
//     //   ].concat(meta)}
//     // />
//   )
// }

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
  image: null,
  pathname: null,
  article: false,
};

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
  image: PropTypes.string,
  pathname: PropTypes.string,
  article: PropTypes.bool,
};

export default SEO
